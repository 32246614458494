<template>
    <div class="cardCustom">
        <div class="upBlock">
            <span class="number">
                {{ this.obj.user.userPhone ||
                    this.obj.user.userPhone !== "" ? 
                    this.obj.user.userPhone : this.$t('Null')
                }}
            </span>
            <span class="date">
                {{ 
                    new Date(this.obj.datetime).
                    toLocaleDateString('uk-UA', { 
                        day: '2-digit', 
                        month: 'long', 
                        year: 'numeric' 

                    })
                }}
            </span>
            <span class="rating">
                <div class="cardCustom_body_right__rating">
                    <i class="ri-star-fill" :style="this.getColorOfRating(Number(this.obj.rating).toFixed(2))"></i>
                    {{ Number(this.obj.rating).toFixed(2) }}
                </div>
            </span>
        </div>
        <hr 
        style="
            margin: 24px 0; 
            width: 60%; 
            background-color: rgb(122 122 122);
        "/>
        <div class="commentBlock">
            {{ this.obj.comment }}
        </div>
        <div class="actionBlock" style="margin-top: 20px;">
            <button 
                v-if="this.obj.user?.userPhone && this.obj.user?.userPhone !== ''"
                @click="this.$emit('showCommentWindow', this.obj)" 
                class="btn btn-success" 
                style="width: 100%;"
            >
                {{ $t('AddApplication') }}
            </button>
        </div>
    </div>
</template>

<script>
export default {
    props: ['obj'],
    methods: {
        getColorOfRating(rating) {
            if(rating >= 4) {
                return 'color: #0ab39c'
            } else if(rating < 3) {
                return 'color: red'
            } else {
                return 'color: #ff9d00'
            }
        }
    }
}

</script>

<style>
    .cardCustom {
        min-width: 400px;
        padding: 24px;
        background-color: white;
        border-radius: 16px;
        box-shadow: 0px 4px 4px 0px #939393;
    }
    .upBlock {
        display: grid;
        grid-template-columns: 4fr 1fr;
        grid-template-rows: repeat(2, 1fr);
    }
    .number {
        font-weight: 700;
    }
    .date {
        grid-row-start: 2;
        grid-row-end: 3;
        grid-column-start: 1;
        grid-column-end: 2;
        color: #717276
    }
    .rating {
        align-self: center;
        justify-self: center;
        grid-row-start: 1;
        grid-row-end: 3;
    }
    .cardCustom_body_right__rating {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;
        font-size: 18px;
    }
</style>