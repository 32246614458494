<!-- Компонент нової шапки -->

<template>

    <b-row>
        <b-col lg="12">
            <div class="d-flex align-items-center">
                <h1 class="mb-0 fw-bold">{{ form.title }}</h1>
                <b-badge v-if="form.checkBeta" variant="danger" class="ms-2 fs-12">Beta</b-badge>
                <b-badge v-if="form.checkNew" variant="info" class="ms-2 fs-12 text-white">New</b-badge>
            </div>
            <p class="text-muted mt-1 fs-14">
                {{ form.desc }}
            </p>
            <hr />
        </b-col>
        
    </b-row>
    
</template>
  
<script >

export default {
    props: ["objSection"],
    data(){
        return {
            form: ''
        }
    },
    created(){
        this.form = this.objSection
    }
}

</script>

<style scoped>
hr {
    background: #1e20251f;
}
</style>
  