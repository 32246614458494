<template>
    <!-- 
        Шапка розділу
        - objSection - 
        - - title - назва розділу
        - - desc - опис розділу
        - - checkBeta - бейдж на бету
        - - checkNew - бейдж на new
    -->
    <!-- <headerSection 
        :objSection="objSection"
    /> -->

    <!-- 
        Фільтри
    -->
    <!-- <filterSection 
        :objFilter="objFilter"
        @changeTab="changeMode"
    /> -->

    <!-- Картки відгуків -->
    <template
        v-if="this.reviews.length > 0"
    >
        <div style="display: flex; gap: 20px; flex-wrap: wrap;">
            <ReviewCard
                v-for="item in reviews"
                :obj="item"
                @showCommentWindow="fillForm"
            />
        </div>
    </template>

    <dialogModal 
        v-if="this.showCommentWindow === true" 
        @close="this.showCommentWindow = false"
    >
        <template v-slot:title>
            {{ $t('Comment') }}
        </template>
        <template v-slot:body>
            <div class="mb-3">
                <label for="exampleFormControlTextarea1" class="form-label">{{ $t("Comment") }}</label>
                <textarea 
                    class="form-control" 
                    id="exampleFormControlTextarea1" 
                    rows="4"
                    v-model="this.applicForm.comment"
                />
            </div>
        </template>
        <template v-slot:footer-bottom>
            <button class="btn btn-success" @click="addRequest">
                {{ $t("Add") }}
            </button>
        </template>
    </dialogModal>
</template>

<script>
import headerSection from '@/components/headerSection/headerSection.vue';
import filterSection from '@/components/headerSection/filterSection.vue';
import ReviewCard from './components/reviewCard.vue';
import dialogModal from "@/components/modal/dialog.vue"
import { Reviews } from '../../../API_Dash';
import { Applications } from '../../../API';

let apiServe = new Reviews()
let apiApplic = new Applications()

export default {
    data() {
        return {
            reviews: [],
            showCommentWindow: false,
            applicForm: {
                userPhone: "",
                comment: "",
                status: "new",
                priority: 2
            }
        }
    },
    components: {
        headerSection,
        filterSection,
        ReviewCard,
        dialogModal
    },
    created() {
        this.getData()
    },
    methods: {
        fillForm(obj) {
            this.applicForm.userPhone = obj.user.userPhone
            this.applicForm.comment = 
`Створено з відгуку клієнта: ${obj.user.userPhone}. 
Відгук надійшов з компанії: ${obj.companyId}
Відгук: ${obj.comment}`
            this.showCommentWindow = true
        },
        getData() {
            apiServe.getReviews({page: '1', pagelimit: '10'}, [this.$route.params.id]).then(result => {
                if(result.status === 'done') {
                    this.reviews = result.data.reviews
                }
            })
        },
        addRequest() {
            apiApplic.addRequest(this.applicForm).then(result => {
                if(result.status === 'done') {
                    this.applicForm.userPhone = "";
                    this.applicForm.comment = "";
                    this.showCommentWindow = false;
                }
            })
        }
    }
}

</script>