<!-- Компонент фільтру під нову шапку -->

<template>
    
    <div class="row g-2" >
        

        <!-- Пошук -->
        <div 
            v-if="objFilter.checkSearch"
            class="col-lg-2 col-auto" 
        >
            <div class="search-box" >
                <input type="text" class="form-control search" id="search-task-options" placeholder="Пошук">
                <i class="ri-search-line search-icon"></i>
            </div>
        </div>

        <div class="col-auto ms-sm-auto" >
            
            <div class="list-grid-nav hstack gap-1" v-if="this.objFilter.checkChangeTab === true">

                <!-- Кнопка відображення grid -->
                <button 
                    type="button" 
                    id="grid-view-button" 
                    :class="`btn btn-soft-info nav-link btn-icon fs-14 ${form.typeList == 0 ? 'active' : ''} filter-button`" 
                    @click="form.typeList = 0, this.$emit('changeTab', form.typeList)"
                >
                    <i class="ri-grid-fill"></i>
                </button>

                <!-- Кнопка відображення таблицею -->
                <button 
                    type="button" 
                    id="list-view-button" 
                    :class="`btn btn-soft-info nav-link  btn-icon fs-14 ${form.typeList == 1 ? 'active' : ''} filter-button`" 
                    @click="form.typeList = 1, this.$emit('changeTab', form.typeList)"
                >
                    <i class="ri-list-unordered"></i>
                </button>
                
            </div>

        </div>
        <!--end col-->
    </div>
    

</template>

<script>
export default {
    props: ["objFilter"],
    data() {
        return {
            form: {
                typeList: null, // 0 - grid, 1 - list
            },
        }
    },
    created(){
        this.form.typeList = this.objFilter.defaultType ? this.objFilter.defaultType : 1
    }
}
</script>